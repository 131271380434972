import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { SharedModule } from '../../shared.module';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { OrganizationService } from '../../services/organization/organization.service';
import { CommonServiceService } from '../../services/common-service.service';
import { UserService } from '../../services/user/user.service';
import { TeammateService } from '../../services/teammateService/teammate.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Contact } from '../../interface/contact';
import lodash from 'lodash';
import moment from 'moment';
import { LoaderService } from '../../services/loader/loader.service';
import { EventService } from '../../services/event/event.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-edit-school-info',
  standalone: true,
  imports: [
    SharedModule,
    PasswordModule,
    InputSwitchModule,
    CheckboxModule,
    FormsModule,
  ],
  providers: [
    {
      provide: NgControl,
      useExisting: forwardRef(() => EditSchoolInfoComponent),
    },
  ],
  templateUrl: './edit-school-info.component.html',
  styleUrl: './edit-school-info.component.scss',
})
export class EditSchoolInfoComponent implements OnInit {
  @Output() closeModelEvent = new EventEmitter<any>();
  @Input() isFromSetPw = false;
  @Input() schoolData: any;
  @Input() contactData: any;
  @Input() isFromSetting: boolean = false;
  accessList: string[] = ['Admin', 'Normal'];
  selectedAccess: string = null;
  title: string = null;
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  phone: string = null;
  contactForm: FormGroup;
  passwordForm: FormGroup;
  isEdit: boolean = false;
  imageFile: File;
  imagePreview: string | ArrayBuffer;
  isDisabled: boolean = false;
  orgRoleList = [];
  orgList: any[] = [];
  removedContacts: any[] = [];

  constructor(
    private fb: FormBuilder,
    private orgService: OrganizationService,
    private commonFun: CommonServiceService,
    private teamMateService: TeammateService,
    private userService: UserService,
    private firebaseStorage: AngularFireStorage,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private eventService: EventService,
    private db: AngularFireDatabase,
    private confirmationService: ConfirmationService
  ) {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [
        '',
        [Validators.required, this.passwordMatchValidator.bind(this)],
      ],
    });

    this.contactForm = this.fb.group({
      title: [''],
      role: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone: [''],
      // access: [""]
    });
  }

  ngOnChanges() {
    if (this.contactData?.isEdit) {
      this.isEdit = true;
      if(this.contactData?.isMainAdmin) {
        this.contactForm.controls['email'].disable();
      } else {
        this.contactForm.controls['email'].enable();
      }
      this.patchVal();
    } else {
      this.isEdit = false;
      this.contactForm.controls['email'].enable();
    }
  }

  async ngOnInit() {
    await this.getOrgs();
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const passwordControl = control.root.get('password');
    const confirmPasswordControl = control.root.get('confirmPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    if (password !== confirmPassword) {
      confirmPasswordControl.setErrors({ passwordsNotMatch: true });
      return { passwordsNotMatch: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  }

  async getOrgs() {
    this.orgList = await this.eventService.getOrgs();
  }

  get contactFormControls() {
    return this.contactForm.controls;
  }

  closeModel(refreshList: boolean = false) {
    this.closeModelEvent.emit({ refreshList });
    this.passwordForm.reset();
    this.contactForm.reset();
    this.imagePreview = null;
  }

  async addEditContact() {
    this.loaderService.show();
    this.contactForm.markAllAsTouched();
    if (this.contactForm.invalid) {
      this.isDisabled = false;
      this.loaderService.hide();
      return;
    }

    this.isDisabled = true;
    const now = moment().valueOf();

    const contactData: Contact = {
      meta: {
        firstName: this.contactFormControls['firstName'].value || '',
        lastName: this.contactFormControls['lastName'].value || '',
        createdAt: this.isEdit ? this.contactData.meta?.createdAt : now,
        phone: this.contactFormControls['phone'].value || '',
        email: this.contactFormControls['email'].value || '',
        role: this.contactFormControls['role'].value || '',
        title: this.contactFormControls['title'].value || '',
      },
      settings: {
        visuals: {
          contactImage: null,
        },
      },
    };

    let contactId = this.isEdit
      ? this.contactData.key
      : this.commonFun.createFirebasePushId();

    if (this.imageFile) {
      await new Promise((resolve, reject) => {
        try {
          this.deleteUploadContactImage(
            this.imageFile,
            contactId,
            (contactImage) => {
              contactData.settings.visuals.contactImage =
                contactImage.downloadURL;
              resolve(contactImage);
            }
          );
        } catch (e) {
          reject(e);
        }
      });
    } else if (
      this.contactData?.settings?.visuals?.contactImage &&
      lodash.isEmpty(this.imagePreview)
    ) {
      await new Promise((resolve, reject) => {
        try {
          this.deleteUploadContactImage(null, this.contactData?.key, () => {
            contactData.settings.visuals.contactImage = null;
            resolve('');
          });
        } catch (e) {
          reject(e);
        }
      });
    }
    if (this.isEdit) {
      contactData.meta.updatedAt = now;
    }
    if (this.isFromSetting) {
      let settingPath = `portal/studioRep/${this.userService.studioID}/${contactId}`;

      if(this.contactData.isMainAdmin){
        await this.teamMateService.updateStudio({
          adminPhone: contactData.meta.phone,
          contactName: contactData.meta.firstName + ' ' + contactData.meta.lastName
        }, this.userService.studioID)
      }
      if(this.contactData.isSupportStaff) {
        await this.teamMateService.updateStudio({
          supportEmailID: contactData.meta.email,
          supportPhoneNo: contactData.meta.phone,
          supportStaffName: contactData.meta.firstName + ' ' + contactData.meta.lastName
        }, this.userService.studioID)
      }
      this.teamMateService
        .createUpdateSetting(contactData, settingPath)
        .then(async () => {
          this.loaderService.hide();
          this.isDisabled = false;
          const action = this.isEdit ? 'updated' : 'added';
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Representative ${action} successfully.`,
          });
          this.closeModel(true);
        })
        .catch((error) => {
          this.loaderService.hide();
          this.isDisabled = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.commonFun.prepareErrorMessage(error),
          });
          throw error;
        });
    } else {
      let contactPath = `organizations/${this.schoolData.key}/portalContact/${contactId}`;
      this.orgService
        .updateOrg(contactData, contactId, contactPath)
        .then(() => {
          this.loaderService.hide();
          this.isDisabled = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Contact ${
              this.isEdit ? 'updated' : 'added'
            } successfully.`,
          });
          this.closeModel(true);
        })
        .catch((error) => {
          this.loaderService.hide();
          this.isDisabled = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.commonFun.prepareErrorMessage(error),
          });
          throw error;
        });
    }
  }

  deleteUploadContactImage(
    file,
    contactId,
    callback: (contactImage: any) => void
  ) {
    const filePath = `/photos/contacts/${contactId}/contactImage`;
    const fileRef = this.firebaseStorage.ref(filePath);
    if (file) {
      const task = this.firebaseStorage.upload(filePath, file);
      task.snapshotChanges().subscribe({
        next: (snapshot) => {
          if (snapshot.state === 'success') {
            fileRef.getDownloadURL().subscribe({
              next: (downloadURL) => {
                callback({ filePath, downloadURL });
              },
              error: (err) => {
                console.error(err);
              },
            });
          }
        },
      });
    } else if (!file && this.isEdit) {
      // remove image files
      fileRef.getDownloadURL().subscribe({
        next: (ref) => {
          try {
            fileRef.delete();
            callback('');
          } catch (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: this.commonFun.prepareErrorMessage(error),
            });
            throw error;
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: this.commonFun.prepareErrorMessage(err),
          });
          throw err;
        },
      });
    } else {
      callback('');
    }
  }

  onFileSelected(event: any) {
    let files = event.target.files || event.dataTransfer.files;
    this.imageFile = files[0];
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }

  async removeLogo() {
    if (this.imagePreview || this.imageFile) {
      this.imagePreview = null;
      this.imageFile = null;
    }
  }

  imgError(event) {
    this.imagePreview = null;
  }


  patchVal() {
    this.contactForm.patchValue({
      title: this.contactData?.meta?.title || '',
      role: this.contactData?.meta?.role || '',
      firstName: this.contactData?.meta?.firstName || '',
      lastName: this.contactData?.meta?.lastName || '',
      email: this.contactData?.meta?.email || '',
      phone: this.contactData?.meta?.phone || '',
    });
    this.imagePreview = this.contactData?.settings?.visuals?.contactImage;
  }
}
